import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../scss/modules/examens-page-component.scss"
import LazyBackground from "../components/lazyBackground"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo
      title={data.allStrapiSeoListExamens.nodes[0].SEO_titre}
      description={data.allStrapiSeoListExamens.nodes[0].SEO_description}
    />
    <ul className={"item-wrapper col-md-11"}>
      {data.allStrapiExamens.nodes
        .sort((a, b) => (a.poids > b.poids ? 1 : -1))
        .map((document, key) => (
          <Link
            to={`/Examens/${document.titre}`}
            className={"item-exam-link"}
            key={key}
          >
            <li style={{ width: "260px", height: "260px" }}>
              <LazyBackground
                className={"item-exam"}
                source={document.image}
                items={
                  <div>
                    <span className={"effet"}></span>
                    <h3 className={"title-exam"}>{document.titre}</h3>
                  </div>
                }
              />
            </li>
            {/*     <li
              key={document.id}
              style={
                document.image && document.image.length
                  ? {
                      backgroundImage: `url(https://admin.radiologieclaudegalien.fr${document.image[0].url})`,
                    }
                  : { background: "#0D3F62" }
              }
              className={"item-exam"}
            >
              
            </li> */}
          </Link>
        ))}
      <div className={"circles"}>
        <span className={"circlesOne"}></span>
        <span className={"circlesTwo"}></span>
        <span className={"circlesThird"}></span>
        <span className={"circlesFourth"}></span>
        <span className={"circlesFifth"}></span>
      </div>
    </ul>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query QueryExamens {
    allStrapiExamens {
      nodes {
        titre
        description
        poids
        image {
          url
        }
      }
    }
    allStrapiSeoListExamens {
      nodes {
        SEO_titre
        SEO_description
      }
    }
  }
`
