import { useEffect, useState } from "react"

const SourceBackground = props => {
  const ndm = "https://admin.radiologiecreteil.fr"
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (props >= 0) {
      setUrl(null)
    } else {
      const img = new Image()
      img.src = `${ndm}${props[0].url}`
      img.onload = () => setUrl(`${ndm}${props[0].url}`)
    }
  }, [props])

  return url
}

export default SourceBackground
